<template>
  <div>
    <v-layout class="cards" justify-start wrap>
      <v-flex class="full-width full-height">
        <v-card
          v-if="$vuetify.breakpoint.mdAndUp"
          class="business"
          color="white"
          flat
        >
          <div
            :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
            class="d-flex full-width"
          >
            <div v-if="!subscriptionLoaded" class="titles">
              <div class="income" style="width: 332px">
                <div
                  v-if="!subscriptionLoaded"
                  class="default_gray"
                  style="width: 5em; height: 2em"
                />
              </div>
              <div
                v-if="!subscriptionLoaded"
                class="default_gray"
                style="width: 6em; height: 1em"
              />
            </div>
            <div v-else class="titles">
              <v-layout align-center justify-center>
                <v-flex style="width: 320px">
                  <div class="income mb-2">
                    <h2 style="display: inline">Webhook Signature Key</h2>
                  </div>
                  <div class="income">
                    <span
                      >این کلید جهت صحت سنجی درخواست‌های دریافتی (وب هوک) از
                      وندار مورد استفاده قرار می‌گیرد.</span
                    >
                    <span class="linkBorder mr-2 flex-no-fill">
                      <button
                        class="small mb-0 v-btn v-btn--flat theme--light"
                        data-v-2662be03=""
                        style="font-size: 12px !important"
                        type="button"
                      >
                        <a
                          href="https://vandarpay.github.io/docs/security/"
                          style="text-decoration: none"
                          target="_blank"
                          ><span class="v-btn__content">مستندات</span></a
                        >
                      </button>
                    </span>
                  </div>
                </v-flex>
              </v-layout>
            </div>
            <v-fade-transition>
              <div v-if="showHasKeyMessage" class="more-data">
                <div class="bank-list">
                  <div
                    class="d-flex align-center justify-center webhook-description px-10 py-2 rounded"
                  >
                    <span>در حال حاضر شما یک کلید فعال دارید</span>
                  </div>
                </div>
              </div>
            </v-fade-transition>
            <v-layout class="mr-auto" column>
              <div
                v-if="$vuetify.breakpoint.mdAndUp"
                class="d-flex align-center justify-end"
              >
                <div
                  v-if="!subscriptionLoaded"
                  class="default_gray"
                  style="width: 4em; height: 2em"
                />
                <v-btn
                  v-else-if="subscriptionLoaded && !hasKey"
                  :disabled="generateLoading"
                  :loading="generateLoading"
                  class="btn_small_normal"
                  depressed
                  outlined
                  text
                  @click="generateKey"
                >
                  دریافت کنید
                </v-btn>
                <v-btn
                  v-else
                  :disabled="regenerateLoading"
                  class="btn_small_normal"
                  depressed
                  outlined
                  text
                  @click="regenerateKey"
                >
                  کلید جدید
                </v-btn>
              </div>
            </v-layout>
          </div>
        </v-card>
        <v-card v-else class="business-md-down" color="white" flat>
          <v-layout justify-space-between wrap>
            <v-flex>
              <div v-if="!subscriptionLoaded" class="titles">
                <div class="income" style="width: 332px">
                  <div
                    v-if="!subscriptionLoaded"
                    class="default_gray"
                    style="width: 5em; height: 2em"
                  />
                </div>
                <div
                  v-if="!subscriptionLoaded"
                  class="default_gray"
                  style="width: 6em; height: 1em"
                />
              </div>
              <div v-else class="titles">
                <div
                  class="income mb-2"
                  :class="{
                    'd-flex align-center justify-space-between':
                      $vuetify.breakpoint.xsOnly,
                  }"
                >
                  <h2
                    style="display: inline"
                    :class="{ 'mb-0': $vuetify.breakpoint.xsOnly }"
                  >
                    Webhook Signature Key
                  </h2>
                  <v-flex style="max-width: 40px; margin-left: -10px">
                    <v-menu
                      bottom
                      close-on-content-click
                      content-class="up_menu"
                      offset-y
                      transition="slide-y-transition"
                    >
                      <!-- this on slot sometime is undefined -->
                      <template #activator="{ on }">
                        <v-btn
                          fab
                          icon
                          size="24"
                          small
                          style="margin: 0"
                          v-on="on"
                        >
                          <v-icon
                            color="van_color03"
                            size="21"
                            style="line-height: 40px"
                          >
                            more_vert
                          </v-icon>
                        </v-btn>
                      </template>

                      <v-card class="drop_down_menu">
                        <v-list class="menu_v_list">
                          <v-list-item v-if="hasKey" @click="regenerateKey">
                            <v-list-item-content>
                              <v-list-item-title>کلید جدید</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-else @click="generateKey">
                            <v-list-item-content>
                              <v-list-item-title>دریافت کنید</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </v-flex>
                </div>
                <div class="income">
                  <span
                    >این کلید جهت صحت سنجی درخواست‌های دریافتی (وب هوک) از وندار
                    مورد استفاده قرار می‌گیرد.</span
                  >
                  <span class="linkBorder mr-2 flex-no-fill">
                    <button
                      class="small mb-0 v-btn v-btn--flat theme--light"
                      data-v-2662be03=""
                      style="font-size: 12px !important"
                      type="button"
                    >
                      <a
                        href="https://vandarpay.github.io/docs/security/"
                        style="text-decoration: none"
                        target="_blank"
                        ><span class="v-btn__content">مستندات</span></a
                      >
                    </button>
                  </span>
                </div>
              </div>
              <v-fade-transition>
                <div v-if="showHasKeyMessage" class="more-data my-3">
                  <div class="bank-list">
                    <div
                      class="d-flex align-center justify-center webhook-description px-10 py-2 rounded"
                    >
                      <span>در حال حاضر شما یک کلید فعال دارید</span>
                    </div>
                  </div>
                </div>
              </v-fade-transition>
            </v-flex>
          </v-layout>
          <v-layout align-center class="more-data" fill-height justify-center />
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
      v-model="webhookSignatureGeneratedModal"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '360'"
      no-click-animation
      transition="slide-x-transition"
    >
      <v-card>
        <div class="d-flex flex-column align-center justify-center pa-10">
          <svg
            class="mb-6"
            fill="none"
            height="63"
            viewBox="0 0 64 63"
            width="64"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.88353 57.1356C8.18007 60.9173 12.6714 63 17.5301 63C18.332 63 19.1537 62.9435 19.9722 62.8321C31.6765 61.2392 42.1195 59.0562 42.5188 58.9724C43.9145 58.6099 45.3177 58.2838 46.6748 57.9684C51.545 56.8365 56.1451 55.7674 59.1796 53.2982C62.5127 50.5858 63.6245 46.4215 62.6785 40.1928C60.7381 27.4157 59.2617 21.441 58.7673 19.6215L58.3657 18.0215C56.93 11.9598 54.7238 7.57339 51.6353 4.63128C48.3643 1.51524 44.0759 0.000498265 38.5253 0.000498265L38.5205 0.000488281C37.7957 0.000488281 31.0524 0.107788 20.2023 3.8416C15.2484 5.54638 9.6545 7.72071 5.72624 10.9216C3.84316 12.456 2.46737 14.1077 1.52026 15.9709C0.49728 17.9833 0 20.1878 0 22.7103V43.986C0 48.7825 1.77998 53.5753 4.88353 57.1356Z"
              fill="#4C9F87"
              opacity="0.1"
            />
            <path
              d="M28.1305 35.6355L23.8633 31.282L22.4102 32.754L28.1305 38.59L40.4102 26.062L38.9673 24.59L28.1305 35.6355Z"
              fill="#4C9F87"
            />
          </svg>
          <h3 class="mb-4">کلید ایجاد شد</h3>
          <p class="text-center modal-description mb-6">
            پیش از بستن این پنجره، کلید را کپی کنید. چرا که پس از آن دیگر به
            آنها دسترسی نخواهید داشت.
          </p>
          <div class="d-flex align-center justify-center full-width mb-5">
            <div
              class="d-flex align-center justify-center webhook-description px-3 py-2 rounded ml-1"
            >
              <p class="text-truncate mb-0 token-box">
                {{ token }}
              </p>
            </div>

            <v-btn
              ref="btn"
              class="btn_small_normal van_color06 tblCpy-receipt_url"
              depressed
              style="
                min-width: 80px !important;
                margin: 0 !important;
                padding: 0;
                max-width: 90px;
                height: 36px !important;
              "
              text
              @click="copyToken"
            >
              <v-icon class="ml-1" color="van_color03" size="14">
                content_copy
              </v-icon>
              <span class="font_4">کپی</span>
              <v-tooltip v-model="copyTooltip" top>
                <template #activator="{ on }">
                  <v-btn
                    style="
                      position: absolute;
                      opacity: 0 !important;
                      width: 1px;
                      margin: 0;
                      padding: 0;
                      z-index: -1;
                      left: 10px;
                    "
                    v-on="on"
                  />
                </template>
                <span>کپی شد</span>
              </v-tooltip>
            </v-btn>
            <input id="tokenInput" v-model="token" class="hidden-input" />
          </div>

          <div class="d-flex align-center justify-center full-width">
            <v-btn color="#666" outlined @click="closeGenerateWebhookModal">
              <span class="modal-description">بستن</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="webhookSignatureRegeneratedModal"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '360'"
      no-click-animation
      transition="slide-x-transition"
    >
      <v-card>
        <div class="d-flex flex-column align-center justify-center pa-10">
          <h3 class="mb-4">کلید جدید می‌خواهید؟</h3>
          <p class="text-center modal-description mb-6">
            در صورت دریافت کلید جدید، کلید قبلی منقضی می‌شود.
          </p>
          <div class="d-flex align-center justify-center full-width">
            <v-btn
              :disabled="regenerateLoading"
              :loading="regenerateLoading"
              color="primary"
              depressed
              @click="generateNewKey"
            >
              <span class="modal-description">کلید جدید</span>
            </v-btn>
            <v-btn
              :disabled="regenerateLoading"
              color="#666"
              outlined
              @click="closeRegenerateWebhookModal"
            >
              <span class="modal-description">بازگشت</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "WebhookSignature",
  components: {},
  data() {
    return {
      loadingComponent: true,

      webhookSignatureGeneratedModal: false,
      webhookSignatureRegeneratedModal: false,
      generateLoading: false,
      regenerateLoading: false,
      token: null,
      copyTooltip: false,
    }
  },
  computed: {
    subscription() {
      return this.$store.state.subscription
    },
    hasKey() {
      return this.subscription.has_secret_key
    },
    tokenLoaded() {
      return this.$store.state.signatureHasLoaded
    },
    subscriptionLoaded() {
      return this.$store.state.subscription?.subscriptionLoaded
    },
    showHasKeyMessage() {
      return this.hasKey && this.tokenLoaded
    },
  },
  methods: {
    generateKey() {
      this.generateLoading = true
      this.$http
        .post(
          `${this.$store.getters.apiUrlParamV3}/subscription/application/secret-key`,
          null,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.webhookSignatureGeneratedModal = true
          this.token = response.data.result.secret_key
          this.$store.commit("updateHasSecretKey", true)
          this.$store.commit("setSignatureHasLoaded", true)
        })
        .catch(() => {})
        .finally(() => (this.generateLoading = false))
    },
    regenerateKey() {
      this.webhookSignatureRegeneratedModal = true
    },
    closeGenerateWebhookModal() {
      this.token = null
      this.tokenLoaded = true
      this.webhookSignatureGeneratedModal = false
    },

    closeRegenerateWebhookModal() {
      this.webhookSignatureRegeneratedModal = false
    },

    generateNewKey() {
      this.regenerateLoading = true
      this.$http
        .post(
          `${this.$store.getters.apiUrlParamV3}/subscription/application/secret-key`,
          null,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + this.$store.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.webhookSignatureRegeneratedModal = false
          this.webhookSignatureGeneratedModal = true
          this.token = response.data.result.secret_key
          this.$store.commit("updateHasSecretKey", true)
        })
        .catch(() => {})
        .finally(() => (this.regenerateLoading = false))
    },

    copyToken() {
      /* Get the text field */
      let copyText = document.querySelector("#tokenInput")

      if (copyText) {
        navigator.clipboard.writeText(copyText.value)

        /* Alert the copied text */
        this.copyTooltip = true
        setTimeout(() => {
          this.copyTooltip = false
        }, 2000)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
}

.full-height {
  height: 100% !important;
}

.cards {
  margin-right: -5px;
  margin-left: -5px;

  > .flex {
    align-self: flex-end;
    height: 120px;
    width: 100%;
    margin: 5px 5px;
  }
}

@media (max-width: 1200px) {
  .layout {
    justify-content: center;
  }
}

.bank-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 400px;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}

.image-box {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  width: 56px;
  height: 56px;
  border-radius: 4px;
}

.van-info {
  position: absolute;
  top: 20px;
  left: 20px;
}

.business {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  box-shadow: none;
  margin: auto;
  cursor: default;

  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.business-md-down {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid $van_color11;
  transition: all ease 0.5s;
  overflow: hidden;
  box-shadow: none;
  margin: auto;
  border: 0px;
  cursor: default;

  > :not(:last-child) {
    margin-right: 5px;
    margin-left: 5px;
  }

  .more-data {
    display: flex;
    //width: 250px;
    margin-top: 16px;
  }

  .bus {
    background-color: $van_color11;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 5px 11px 5px;
    height: 80px;
    width: 100%;
    margin: 5px;
    max-width: 95%;

    &.bus-right,
    &.bus-left {
      width: 95%;
    }

    &.flat {
      background: transparent;
      color: $van_pink;
      font-size: 12px;
      font-weight: bold;
      height: 28px;
    }
  }
}

.v-avatar {
  margin: 0 0 10px 0;
}

.titles {
  max-width: 295px;
}

h2 {
  margin: 0 0 9px 0;
  line-height: 20px;
}

.income {
  font-size: 12px;
  color: #666;
  font-weight: normal;

  h2 {
    font-size: 14px;
  }
}

.bus-right {
  margin-left: 10px;
}

.bus {
  background-color: $van_color11;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 11px 5px;
  height: 80px;
  width: 100%;
  max-width: 120px;

  &.bus-right,
  &.bus-left {
    width: 50%;
  }

  &.flat {
    background: transparent;
    color: $van_pink;
    font-size: 12px;
    font-weight: bold;
    height: 28px;
  }
}

.business .more-data {
  display: flex;
  width: 384px;
}

.mablagh {
  font-size: 14px;
  font-weight: bold;
}

.sub-text {
  font-size: 9px;
  color: #999;
}

.layout.botton {
  max-width: 88px;

  .v-btn {
    width: calc(100% - 16px);
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.v-chip--small {
  height: 25px !important;
  margin-top: 5px !important;
}

.full-width {
  width: 100%;
}

.webhook-description {
  width: 100%;
  background-color: #f5f5f5;
  font-size: 11px;
  font-weight: 400;
}

.modal-title {
  font-size: 16px;
}

.modal-description {
  font-size: 12px;
}

.token-box {
  direction: ltr;
  text-align: left;
  max-width: 90%;
  color: #999;
  font-size: 11px;
}
</style>
