<template>
  <div class="home">
    <div v-if="!oneToolIsActive">
      <NewToolsBox />
      <WalletStatus
        v-if="$store.state.business.status === 1"
        style="padding-top: 1px"
        @refreshQueuedCard="refreshQueuedCard"
      />
      <AccountsList v-if="isThereAnyAccounts" />
    </div>

    <!--TODO: plus checking that tools are loaded in business object, i could check that business.name in store = business name in router -->
    <v-layout
      v-else-if="businessHasToolsProperty"
      column
      wrap
      justify-center
      align-start
    >
      <NewToolsBox />
      <v-flex style="height: 100%; width: 100%">
        <WalletStatus @refreshQueuedCard="refreshQueuedCard" />
        <A2aDailyCard
          v-if="isA2aActive && !noActiveBank && !isThereAnyAccounts"
          class="a2aBox"
          :class="{
            'pb-6': reuseIsMobile,
            'pb-14': $vuetify.breakpoint.smAndUp,
          }"
          @noBankIsActive="noActiveBank = true"
        />
        <AccountsList v-if="isThereAnyAccounts" />
      </v-flex>
      <div
        :class="`d-flex w-100 ${
          $vuetify.breakpoint.smAndDown ? 'flex-column' : 'gap-24 mt-8'
        }`"
      >
        <SettlementQueueCard ref="queuedCard" class="col pa-0" />
        <SuspiciousCard
          v-if="$store.state.business?.pic_suspicious_check"
          class="col pa-0"
        />
      </div>
      <section class="chart-title">
        <h2 class="mt-6 mr-0 mb-3 ml-auto">نگاه کلی تراکنش‌ها</h2>
        <KerdarNavigationLink />
      </section>
      <v-flex class="mb-4" style="width: 100%">
        <OverViewDepositEChart :kerdar-lang="kerdarLang" />
      </v-flex>
    </v-layout>

    <v-flex
      v-if="
        $store.state.business.hasOwnProperty('tools') &&
        ($store.state.business.tools.requestMoney?.status == 'ACTIVE' ||
          $store.state.business.tools.ipg?.status == 'ACTIVE' ||
          $store.state.business.tools.p2p?.status == 'ACTIVE' ||
          $store.state.business.tools.subscription?.status == 'ACTIVE')
      "
    >
      <listOfCards :skeleton="!status" />
    </v-flex>
  </div>
</template>

<script>
import listOfCards from "../elements/listOfCards.vue"
// import verificationOnboarding from '../elements/verificationOnboarding.vue'
import WalletStatus from "../../elements/walletStatus"
import A2aDailyCard from "@/components/A2A/elements/a2aDailyCard"
import OverViewDepositEChart from "@/components/kerdar/tabs/overview/elements/OverViewDepositEChart"
import kerdarLang from "@/components/kerdar/utils/langResource.json"
import KerdarNavigationLink from "@/components/kerdar/components/KerdarNavigationLink.vue"
import SuspiciousCard from "@/components/businessHome/elements/suspicious/suspiciousCard.vue"
import SettlementQueueCard from "@/components/businessHome/elements/settlementQueue/settlementQueueCard.vue"
import NewToolsBox from "../elements/NewToolsBox"
import AccountsList from "@/components/businessHome/elements/AccountsList.vue"
import servicesRepository from "@/services/servicesRepository"
import screenSize from "@/mixins/screenSize.js"

export default {
  name: "BusinessHome",
  components: {
    SettlementQueueCard,
    SuspiciousCard,
    A2aDailyCard,
    WalletStatus,
    listOfCards,
    // verificationOnboarding,
    OverViewDepositEChart,
    KerdarNavigationLink,
    NewToolsBox,
    AccountsList,
  },
  mixins: [screenSize],
  data() {
    return {
      status: false,
      //check if business status was not true
      businessVerification: false,
      noActiveBank: false,
      kerdarLang,
    }
  },
  computed: {
    isA2aActive() {
      return this.$store.state.business?.tools?.A2A?.status === "ACTIVE"
    },
    oneToolIsActive() {
      if (!this.businessHasToolsProperty) return false
      for (let tool in this.$store.state.business.tools) {
        if (
          ["ACTIVE"].includes(this.$store.state.business.tools[tool]?.status)
        ) {
          return true
        }
      }
      return false
    },
    businessHasToolsProperty() {
      return Object.prototype.hasOwnProperty.call(
        this.$store.state.business,
        "tools"
      )
    },
    isThereAnyAccounts() {
      return this.$store.state.accountsList?.length > 0
    },
  },
  watch: {
    "$route.params.isFromCreatePage": {
      handler: function (newValue) {
        if (newValue == "yes") {
          this.$store.commit("setStartVerificationBusiness", true)
        } else {
          this.$store.commit("setStartVerificationBusiness", false)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getAccountsList()

    if (this.$route.params.openQueuedModal)
      this.$refs.queuedCard.showQueueItems = this.$route.params.openQueuedModal

    this.$http
      .get(this.$store.getters.apiUrlParam + "/chart", {
        timeout: 0,
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + this.$store.state.token,
          Accept: "application/json",
        },
      })
      .then(() => {
        this.status = true
      })
      .catch((error) => {
        //callsnackbar
      })
  },
  methods: {
    refreshQueuedCard() {
      this.$refs.queuedCard?.getData()
    },
    async getAccountsList() {
      if (this.$store.state.isUser) return
      const response = await servicesRepository(
        this
      ).settlementServices.httpGetAccountsList()
      this.findTheMostUpdatedTime(response.data.data)
      this.$store.commit("setAccountsList", response.data.data)
    },
    findTheMostUpdatedTime(accountsList) {
      let maxUpdatedTime = Math.max(
        ...accountsList.map((item) => item.balance_updated_at)
      )

      this.$store.commit("setTheMostUpdatedTime", maxUpdatedTime)
    },
  },
}
</script>

<style lang="scss" scoped>
.a2aBox {
  margin-top: -40px;
}
@media (min-width: 1200px) {
  .home {
    max-width: 920px;
    margin: auto;
  }
}
.flex.xs4 {
  align-self: flex-end;
  height: 288px;
  min-width: 250px;
}
.theme--light.v-divider {
  border-color: $van_color05;
  border-style: dashed;
}

.chart-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4px;
}
.gap-24 {
  gap: 24px;
}
</style>
