import axios from "axios"
import router from "../router"
import { getCookie, setCookie } from "@/utils/cookie"
import servicesRepository from "@/services/servicesRepository"

export const actions = {
  destroyToken(context) {
    if (context.getters.loggedIn && getCookie("access_token")) {
      return new Promise((resolve, reject) => {
        axios
          .get(context.state.api_base_url_withoutV + "/account/v1/logout", {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + context.state.token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            //make all the store clear
            context.commit("destroyToken")
            resolve(response)
          })
          .catch((error) => {
            context.commit("destroyToken")
            reject(error)
          })
      })
    } else {
      context.commit("destroyToken")
      router.push("/enter")
    }
  },
  refreshToken(context) {
    context.commit("updateWaitForRefreshToken", true)
    return new Promise((resolve, reject) => {
      axios
        .post(
          context.state.api_base_url_withoutV + "/account/v1/refresh-token",
          {
            refresh_token: getCookie("refresh_token"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          context.commit("setCookie", {
            token: response.data.access_token,
            refreshToken: response.data.refresh_token,
            expireTime: response.data.expires_in,
          })
          context.commit("updateWaitForRefreshToken", false)
          resolve(response)
        })
        .catch((error) => {
          context.commit("destroyToken")
          router.push("/enter")
          reject(error)
        })
    })
  },
  retrieveUserObject(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.state.api_base_url + "/personal/info", {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + context.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          context.commit("setUser", response.data.data)
          resolve(response)
        })
        .catch((error) => {
          context.commit("destroyToken")
          reject(error)
        })
    })
  },
  retrieveBusinessObject(context, name) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.state.api_base_url + "/business/" + name)
        .then((response) => {
          context.commit("setBusiness", response.data.data)
          context.dispatch("getSuspiciousCount")
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  activateTelegramBot(context, chatid) {
    //after business name set, call this
    return new Promise((resolve, reject) => {
      axios
        .post(
          context.state.api_base_url + "/personal/update/telegramChatId",
          {
            telegram_chat_id: chatid,
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + context.state.token,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          var obj = {
            title: "درخواست موفقیت‌آمیز بود",
            description: "درخواست فعال‌سازی بات تلگرام برای شما ثبت شد.",
          }
          context.commit("callConfirmarionMessage", obj)
          resolve(response.data.data)
        })
        .catch((error) => {
          //context.commit('callsnackbar', error)
          reject(error)
        })
    })
  },
  retrieveBusinessList(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.state.api_base_url_withoutV + "/v3/business", {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + context.state.token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          context.commit("setBusinessList", response.data.data)
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  //customer actions
  retrieveSelectedCustomerSummarizeObject(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          context.getters.apiUrlParam +
            "/customers/" +
            context.state.customer.selectedCustomer.id +
            "/wallet/summarize"
        )
        .then((response) => {
          context.commit("setCustomerSummarize", response.data.data)
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getIpgProperties(context) {
    if (context.state.ipg && !context.state.ipg.api_key) {
      return new Promise((resolve, reject) => {
        axios
          .get(context.getters.apiWithVersionParam("3") + "/ipg/show", {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + context.state.token,
              Accept: "application/json",
            },
          })
          .then((response) => {
            // this.ipg = response.data.data.ipg
            // if (this.ipg != null) {
            //   this.$store.commit('addIpg', this.ipg)
            // }
            // resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  retrieveSelectedCustomerBalance(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          context.getters.apiUrlParam +
            "/customers/" +
            context.state.customer.selectedCustomer.id +
            "/wallet"
        )
        .then((response) => {
          context.commit("setCustomerBalance", response.data.balance)
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  retrieveSelectedCustomerTransactions(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          context.getters.apiUrlParam +
            "/customers/" +
            context.state.customer.selectedCustomer.id +
            "/transactions"
        )
        .then((response) => {
          context.commit("setCustomerTransactions", response.data.data)
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  retrieveSelectedCustomerInfo(context) {
    return new Promise((resolve, reject) => {
      const id = context.state.customer.selectedCustomer.id
      servicesRepository(context)
        .customers.httpGetCustomerInformation(axios, id)
        .then((response) => {
          context.commit("setCustomerInfo", response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  retrieveSelectedCustomerIbans(context, page = 1, item_per_page = 20) {
    return new Promise((resolve, reject) => {
      servicesRepository(context)
        .customers.httpGetCustomersIbans(axios, page, item_per_page)
        .then((response) => {
          if (page == 1) {
            context.commit("setCustomerIbans", response.data.data)
          } else {
            context.commit("addIbanToCustomerIbans", response.data.data)
          }
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  saveAction(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          context.state.api_base_url_withoutV + "/v2.1/personal/action",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + context.state.token,
            },
          }
        )
        .then((response) => {
          context.commit("changeUserActions", response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  setPbvAppConfigs(context) {
    if (!context.state.pbv.appConfigs) {
      axios
        .get(context.state.api_pbv_url + `/app-configs`)
        .then((response) => {
          context.commit("setPbvAppConfigs", response.data.data)
        })
        .catch(() => {})
    }
  },
  setBusinessCeilingAmount(context) {
    axios
      .post(context.getters.ibanApiUrlParam + "/daily-settlement-limit", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + context.state.token,
          Accept: "application/json",
        },
      })
      .then((response) => {
        context.state.businessCeilingAmount = response?.data[0].total_amount
      })
      .catch(() => {})
  },
  getSuspiciousCount(context) {
    //get count and update suspiciousCount business
    return new Promise((resolve, reject) => {
      axios
        .get(
          context.getters.apiUrlParamV3 + "/cash-in/suspicious-payment/count"
        )
        .then((response) => {
          context.commit("changeSuspiciousCount", response.data.data.count)

          resolve(response)
        })
        .catch((error) => reject(error))
    })
  },
}
