var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-progress-circular',_vm._g({class:[
        'outerProgressCircle',
        !_vm.getRemainedAmount ? 'grayscale' : '',
        _vm.calcRemainingPercentage > 25 ? 'green3' : 'red3',
      ],style:({
        opacity: !_vm.isBankHealthy ? '50%' : '100%',
      }),attrs:{"value":_vm.calcRemainingPercentage,"rotate":-90,"width":"2"}},on),[(_vm.getRemainedAmount && !_vm.isBankHealthy)?_c('v-icon',_vm._g({staticClass:"unhealthy-icon",attrs:{"size":"16"}},on),[_vm._v("mdi_info_outline ")]):_vm._e(),_c('v-progress-circular',{staticClass:"innerProgressCircle",attrs:{"value":_vm.calcRemainingPercentage,"rotate":-90,"width":"1","color":_vm.calcRemainingPercentage > 25 ? 'van_green' : 'van_red'}},[_c('v-img',{attrs:{"src":require(`@/assets/img/banks/${parseInt(_vm.bank.code)}.svg`),"width":"19"}})],1)],1)]}}])},[(_vm.getRemainedAmount !== '0')?_c('span',{staticClass:"tooltipText"},[_vm._v(_vm._s(_vm.remainedAmountText)+" ")]):_c('span',{staticClass:"tooltipText"},[_vm._v(" مبلغ سقف پر شده ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }