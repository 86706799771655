<template>
  <v-flex
    class="info_panel roleInfo"
    style="margin-top: -20px; padding: 7px 20px !important"
  >
    <v-expansion-panels flat>
      <v-expansion-panel v-model="panel" expand>
        <v-expansion-panel-header class="pa-0">
          <div class="font_2 mb-0">
            <v-icon size="16" color="van_color02"> error_outline </v-icon>هر نقش
            چه سطح دسترسی دارد؟
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-0">
          <v-card flat>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="desserts"
                hide-default-footer
                class="small_description role_info"
              >
                <template #item="{ item }">
                  <tr>
                    <td class="text-right info_table_title">
                      {{ item.title }}
                    </td>

                    <td v-if="item.owner" class="text-center info_table_cell">
                      <v-icon color="van_green" size="16"> check </v-icon>
                    </td>
                    <td v-else class="text-center info_table_cell">
                      <v-icon color="van_color07" size="16"> close </v-icon>
                    </td>

                    <td v-if="item.manager" class="text-center info_table_cell">
                      <v-icon color="van_green" size="16"> check </v-icon>
                    </td>
                    <td v-else class="text-center info_table_cell">
                      <v-icon color="van_color07" size="16"> close </v-icon>
                    </td>

                    <td
                      v-if="item.accountant"
                      class="text-center info_table_cell"
                    >
                      <v-icon color="van_green" size="16"> check </v-icon>
                    </td>
                    <td v-else class="text-center info_table_cell">
                      <v-icon color="van_color07" size="16"> close </v-icon>
                    </td>

                    <td
                      v-if="item.operator"
                      class="text-center info_table_cell"
                    >
                      <v-icon color="van_green" size="16"> check </v-icon>
                    </td>
                    <td v-else class="text-center info_table_cell">
                      <v-icon color="van_color07" size="16"> close </v-icon>
                    </td>

                    <td
                      v-if="item.observer"
                      class="text-center info_table_cell"
                    >
                      <v-icon color="van_green" size="16"> check </v-icon>
                    </td>
                    <td v-else class="text-center info_table_cell">
                      <v-icon color="van_color07" size="16"> close </v-icon>
                    </td>

                    <td
                      v-if="item.programmer"
                      class="text-center info_table_cell"
                    >
                      <v-icon color="van_green" size="16"> check </v-icon>
                    </td>
                    <td v-else class="text-center info_table_cell">
                      <v-icon color="van_color07" size="16"> close </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-flex>
</template>

<script>
export default {
  name: "RoleInfo",
  data: function () {
    return {
      panel: [true],
      desserts: [
        {
          title: "مشاهده هشدارهای سیستم",
          owner: true,
          manager: true,
          accountant: true,
          operator: true,
          observer: true,
          programmer: true,
        },
        {
          title: "مشاهده گزارش تراکنش‌ها",
          owner: true,
          manager: true,
          accountant: true,
          operator: true,
          observer: true,
          programmer: false,
        },
        {
          title: "ساخت و مشاهده تیکت",
          owner: true,
          manager: true,
          accountant: true,
          operator: true,
          observer: true,
          programmer: true,
        },
        {
          title: "مشاهده فرم‌های پرداخت",
          owner: true,
          manager: true,
          accountant: true,
          operator: true,
          observer: true,
          programmer: false,
        },
        {
          title: "ساخت فرم پرداخت",
          owner: true,
          manager: true,
          accountant: true,
          operator: true,
          observer: false,
          programmer: false,
        },
        {
          title: "درخواست بازگشت وجه",
          owner: true,
          manager: true,
          accountant: true,
          operator: false,
          observer: false,
          programmer: false,
        },
        {
          title: "درخواست برداشت",
          owner: true,
          manager: true,
          accountant: true,
          operator: false,
          observer: false,
          programmer: false,
        },
        {
          title: "مدیریت شماره شبا",
          owner: true,
          manager: true,
          accountant: true,
          operator: false,
          observer: false,
          programmer: false,
        },
        {
          title: "مشاهده و تغییر تنظیمات درگاه",
          owner: true,
          manager: true,
          accountant: false,
          operator: false,
          observer: false,
          programmer: true,
        },
        {
          title: "مدیریت کاربران",
          owner: true,
          manager: true,
          accountant: false,
          operator: false,
          observer: false,
          programmer: false,
        },
        {
          title: "تنظیمات کسب‌و‌کار",
          owner: true,
          manager: true,
          accountant: false,
          operator: false,
          observer: false,
          programmer: false,
        },
        {
          title: "انتقال مالکیت",
          owner: true,
          manager: false,
          accountant: false,
          operator: false,
          observer: false,
          programmer: false,
        },
      ],
      headers: [
        {
          text: "",
          align: "center",
          sortable: false,
          value: "title",
        },
        {
          text: "مالک",
          align: "center",
          sortable: false,
          value: "owner",
        },
        {
          text: "مدیر",
          align: "center",
          sortable: false,
          value: "manager",
        },
        {
          text: "حسابدار",
          align: "center",
          sortable: false,
          value: "accountant",
        },
        {
          text: "اپراتور",
          align: "center",
          sortable: false,
          value: "operator",
        },
        {
          text: "ناظر",
          align: "center",
          sortable: false,
          value: "observer",
        },
        {
          text: "برنامه‌نویس",
          align: "center",
          sortable: false,
          value: "programmer",
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
.info_table_cell {
  text-align-last: center;
}
</style>
<style lang="scss">
.role_info {
  thead,
  th {
    border: none !important;
    height: 40px !important;
  }
}
</style>
