<template>
  <div class="home">
    <template v-if="$store.state.isUser">
      <activationBlock
        v-if="$store.state.user.hasOwnProperty('status') && isUserStatusPending"
      />
      <p2p-activation-block-user
        v-if="
          $store.state.user.hasOwnProperty('tools') &&
          $store.state.user.tools.p2p.status === 'PENDING'
        "
      />
    </template>
    <template v-else>
      <!-- suspicious message -->
      <general-yellow-bar-message
        v-if="checkSuspiciousCount"
        icon="yellow_box_ipg_vip_icon.svg"
      >
        <template #text>
          <div class="font-size-14 font-weight-bold van_color02--text">
            کاربر گرامی
          </div>
          <div class="pl-10">
            شما می‌توانید تراکنش‌های واریز شناسه‌داری را که با شماره شبای
            تایید‌نشده پرداخت شده‌اند، از طریق «میز‌کار» مشاهده کرده و تعیین
            تکلیف کنید. برای هر تراکنش تنها ۵ روز از زمان ایجاد آن فرصت دارید تا
            وضعیت را مشخص کنید. بعد از این مدت زمان و یا در صورت رد تراکنش، مبلغ
            با کسر کارمزد بانک از محل پرداخت مشتری، به حساب مبدا بازگردانده
            می‌شود.
          </div>
        </template>
        <!--        <template #action>-->
        <!--          <v-btn-->
        <!--            id="cash_in"-->
        <!--            text-->
        <!--            dark-->
        <!--            outlined-->
        <!--            class="btn_small_normal mr-0"-->
        <!--            @click=""-->
        <!--          >-->
        <!--            متوجه شدم-->
        <!--          </v-btn>-->
        <!--        </template>-->
      </general-yellow-bar-message>

      <!--      <general-yellow-bar-message-->
      <!--        v-if="$store.state?.business?.need_shaparak_iban"-->
      <!--        icon="ibanShaparak.svg"-->
      <!--      >-->
      <!--        <template #text>-->
      <!--          <div class="pl-10">-->
      <!--            از تاریخ ۲۸ فروردین ۱۴۰۲ تراکنش‌های درگاه و فرم پرداخت نزد شاپرک-->
      <!--            تجمیع می‌شود و روزانه طی یک تراکنش تسویه، توسط شاپرک به حساب بانکی-->
      <!--            شما واریز می‌شوند. برای تسویه این مبالغ لازم است شماره شبای-->
      <!--            موردنظرتان را از طریق «تنظیمات کسب‌و‌کار» معرفی کنید.-->
      <!--            <span class="linkBorder van_green&#45;&#45;text text-no-wrap">-->
      <!--              <a-->
      <!--                class="font-size-11"-->
      <!--                href="https://b2n.ir/y76778"-->
      <!--                target="_blank"-->
      <!--                >اطلاعات بیشتر</a-->
      <!--              >-->
      <!--            </span>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--        <template #action>-->
      <!--          <v-btn-->
      <!--            id="cash_in"-->
      <!--            text-->
      <!--            dark-->
      <!--            outlined-->
      <!--            style="width: 132px"-->
      <!--            class="btn_small_normal mr-0"-->
      <!--            @click="changeShebaIban"-->
      <!--          >-->
      <!--            تغییر شبا تسویه-->
      <!--          </v-btn>-->
      <!--        </template>-->
      <!--      </general-yellow-bar-message>-->

      <v-layout
        v-if="isUserStatusPending"
        class="activationBlock"
        :align-start="$vuetify.breakpoint.xsOnly"
      >
        <span class="topBack not-complete-state" />

        <v-img
          width="100px"
          height="100px"
          max-width="100px"
          max-height="100px"
          class="ml-4"
          :class="{ 'd-none': $vuetify.breakpoint.xsOnly }"
          :src="require('@/assets/img/soft-pending.svg')"
        />
        <section class="pending-info-confirm">
          <h1 class="pending-info-confirm__title">در انتظار تایید حساب شخصی</h1>
          <span class="pending-info-confirm__subtitle">
            پس از تایید اطلاعات هویتی، حساب شخصی فعال می‌شود.
          </span>
        </section>
      </v-layout>
      <businessActivationBlock v-if="isBusinessBlock" />

      <shaparakActivationBlockForBusiness
        v-if="$store.getters.isShaparakBoxShow"
        @openNewBusinessDialog="createNewBusiness = true"
        @showPendingShaparak="showPendingShaparak = true"
      />

      <refund-activation-block
        v-if="
          $store.state.business.hasOwnProperty('tools') &&
          $store.state.business.tools.refund.status === 'PENDING'
        "
      />
      <p2p-activation-block
        v-if="
          $store.state.business.hasOwnProperty('tools') &&
          $store.state.business.tools.p2p.status === 'PENDING'
        "
      />
      <pbv-activation-block
        v-if="
          $store.state.business.hasOwnProperty('tools') &&
          $store.state.business.tools.PBV.status === 'ACTIVE' &&
          !!$store.state.business.status === false
        "
      />
      <cash-in-activation-block
        v-if="
          $store.state.business.hasOwnProperty('tools') &&
          $store.state.business.tools['cash-in'].status === 'PENDING'
        "
      />
      <ApprovalPendingToolsContainer
        v-if="!isBusinessActive && hasPendingTool"
      />
      <ipgEditBlock
        v-if="
          $store.state.business.hasOwnProperty('ipg_status') &&
          $store.state.business.ipg_status.url.status === 'PENDING'
        "
      />
    </template>

    <general-dialog
      v-if="createNewBusiness"
      :active="createNewBusiness"
      btn1-text="ساخت کسب‌و‌کار"
      btn2-text="تمایل ندارم"
      content-class="newBusinessDialog"
      description="درخواست فعالسازی درگاه پرداخت لغو شد. میتوانید کسب‌و‌کار جدیدی با اطلاعات اینماد ثبت شده، بسازید"
      logo="newIcon/add_business.svg"
      logo-box-class="gray_box_logo"
      title="تمایل دارید کسب‌و‌کار جدید بسازید؟"
      @btn1Clicked="$router.push({ name: 'addBusiness' })"
      @btn2Clicked="createNewBusiness = false"
    />

    <general-dialog
      v-if="showPendingShaparak"
      :active="showPendingShaparak"
      btn2-text="متوجه شدم"
      content-class="newBusinessDialog"
      description="اطلاعات وارد شده، برای بررسی به شاپرک ارسال می‌شوند. در صورت عدم تایید به شما اطلاع می‌دهیم."
      title="اطلاعات ثبت شد"
      @btn2Clicked="showPendingShaparak = false"
    />

    <v-dialog
      v-model="status"
      :content-class="
        $store.state.secondShebamodal ? 'secondModal vanmodal' : 'vanmodal'
      "
      transition="slide-x-transition"
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '600'"
      no-click-animation
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <SettingFinancial v-if="financial" @closeModal="close_modal()" />
    </v-dialog>
  </div>
</template>

<script>
import activationBlock from "@/components/personalHome/elements/activationBlock.vue"
import p2pActivationBlockUser from "@/components/personalHome/elements/p2pActivationBlock.vue"
import shaparakActivationBlockForBusiness from "@/components/businessHome/elements/shaparakActivationBlock.vue"
import businessActivationBlock from "@/components/businessHome/elements/businessActivationBlock.vue"
import refundActivationBlock from "@/components/businessHome/elements/refundActivationBlock"
import p2pActivationBlock from "@/components/businessHome/elements/p2pActivationBlock"
import cashInActivationBlock from "@/components/businessHome/elements/cashInActivationBlock"
import GeneralDialog from "../modals/generalDialog"
import ipgEditBlock from "../businessHome/elements/ipgEditBlock"
import PbvActivationBlock from "@/components/businessHome/elements/pbvActivationBlock"
import GeneralYellowBarMessage from "@/components/elements/generalYellowBarMessage.vue"
import SettingFinancial from "@/components/modals/settingFinancial.vue"
import ApprovalPendingToolsContainer from "@/components/businessHome/elements/ApprovalPendingToolsContainer"
import { mapGetters } from "vuex"

export default {
  name: "ActivationBar",
  components: {
    SettingFinancial,
    GeneralYellowBarMessage,
    PbvActivationBlock,
    GeneralDialog,
    activationBlock,
    businessActivationBlock,
    shaparakActivationBlockForBusiness,
    refundActivationBlock,
    p2pActivationBlock,
    p2pActivationBlockUser,
    cashInActivationBlock,
    ipgEditBlock,
    ApprovalPendingToolsContainer,
  },
  data() {
    return {
      createNewBusiness: false,
      showPendingShaparak: false,
      status: false,
      financial: false,
    }
  },
  computed: {
    ...mapGetters(["isBusinessActive"]),
    checkSuspiciousCount() {
      return this.$store.state.suspiciousCount
    },
    toolsObject() {
      return this.$store?.state?.business?.tools || {}
    },
    hasPendingTool() {
      return Object.values(this.toolsObject).find(
        (tool) => tool.status === "PENDING"
      )
    },
    isUserStatusPending() {
      return this.$store.state.user.status === 0
    },
    isBusinessBlock() {
      const blockStatuses = ["INCOMPLETE", "SOFT-REJECT", "PENDING", "REJECTED"]
      return blockStatuses.includes(this.$store.state.business.business_status)
    },
  },
  watch: {
    status(newValue) {
      if (!newValue) {
        this.financial = false
      }
    },
  },
  mounted() {
    this.$store.dispatch("getIpgProperties")
    this.$store.dispatch("getSuspiciousCount")
  },
  methods: {
    // changeShebaIban() {
    //   this.status = true
    //   this.financial = true
    // },
    close_modal() {
      this.status = false
      this.financial = false
    },
  },
}
</script>
<style lang="scss" scoped>
.pending-info-confirm {
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__title {
    font-size: 20px;
    color: $van_color02;
    line-height: 30px;
    margin-bottom: 8px;
  }

  &__subtitle {
    font-size: 11px;
    font-weight: normal;
    color: $van_color02;
    line-height: 17px;
  }
}
</style>
