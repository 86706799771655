<template>
  <LeftModal
    ref="leftModal"
    toolbar-name="نام حساب"
    @closeModal="$emit('close-modal')"
  >
    <div slot="toolbarItems">
      <v-btn
        ref="btn"
        text
        dark
        class="btn_small_normal"
        :disabled="isDisableSubmitBtn"
        @click="saveAccountName"
      >
        ذخیره
      </v-btn>
    </div>
    <v-form class="box-style radius-4">
      <p class="font-11-no-333-17">
        می‌توانید برای حسابتان یک نام دلخواه انتخاب کنید تا به راحتی حساب را
        پیدا کنید.
      </p>
      <InputContainer label="نام">
        <v-text-field
          v-model.trim="accountNameModel"
          solo
          flat
          height="40"
          placeholder="مثال: حساب تسویه"
          class="account-name"
        >
        </v-text-field>
      </InputContainer>
    </v-form>
  </LeftModal>
</template>

<script>
import LeftModal from "@/components/modals/leftModal"
import InputContainer from "@/components/elements/inputContainer.vue"
import servicesRepository from "@/services/servicesRepository"

export default {
  name: "AccountNameEdition",
  components: {
    LeftModal,
    InputContainer,
  },
  props: {
    account: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      accountName: "",
      isDisableSubmitBtn: true,
    }
  },
  computed: {
    accountNameModel: {
      get() {
        return this.accountName
      },
      set(val) {
        this.accountName = val
        if (val) this.isDisableSubmitBtn = false
        else this.isDisableSubmitBtn = true
      },
    },
  },
  methods: {
    async saveAccountName() {
      const res = await servicesRepository(
        this
      ).settlementServices.httpUpdateAccountName(
        this.account.iban,
        this.accountNameModel
      )
      await this.getAccountsList()
      this.$emit("edit-account-name", this.accountNameModel)
      this.$emit("close-modal")
      this.$store.commit("callSuccessSnackbar", res.data.message)
    },
    async getAccountsList() {
      if (this.$store.state.isUser) return
      const response = await servicesRepository(
        this
      ).settlementServices.httpGetAccountsList()
      this.$store.commit("setAccountsList", response.data.data)
    },
  },
}
</script>

<style lang="scss" scoped>
.box-style {
  border: 1px solid #e5e5e5;
  padding: 24px;
  background: #fff;
}

.radius-4 {
  border-radius: 4px;
}

:deep(.v-text-field.v-text-field--solo .v-input__control input) {
  text-align: right;
}
</style>
