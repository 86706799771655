<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-progress-circular
        :style="{
          opacity: !isBankHealthy ? '50%' : '100%',
        }"
        :class="[
          'outerProgressCircle',
          !getRemainedAmount ? 'grayscale' : '',
          calcRemainingPercentage > 25 ? 'green3' : 'red3',
        ]"
        :value="calcRemainingPercentage"
        :rotate="-90"
        width="2"
        v-on="on"
      >
        <v-icon
          v-if="getRemainedAmount && !isBankHealthy"
          class="unhealthy-icon"
          size="16"
          v-on="on"
          >mdi_info_outline
        </v-icon>
        <v-progress-circular
          class="innerProgressCircle"
          :value="calcRemainingPercentage"
          :rotate="-90"
          width="1"
          :color="calcRemainingPercentage > 25 ? 'van_green' : 'van_red'"
        >
          <v-img
            :src="require(`@/assets/img/banks/${parseInt(bank.code)}.svg`)"
            width="19"
          />
        </v-progress-circular>
      </v-progress-circular>
    </template>
    <span v-if="getRemainedAmount !== '0'" class="tooltipText"
      >{{ remainedAmountText }}
    </span>
    <span v-else class="tooltipText"> مبلغ سقف پر شده </span>
  </v-tooltip>
</template>

<script>
import { numbersHelper } from "@/mixins/numbersHelper.js"

export default {
  name: "A2aDailyCardItem",
  mixins: [numbersHelper],
  props: {
    bank: {
      default: [],
    },
  },
  computed: {
    bankLimits() {
      return this.bank?.a2a?.limit?.amount_limit
    },
    remainedAmountText() {
      if (this.bankLimits?.total === -1 && this.isBankHealthy)
        return `باقی مانده نامحدود`
      if (this.bankLimits?.total !== -1 && this.getRemainedAmount === 0)
        return "باقی مانده تسویه آنی امروز بانک، تمام شده است."

      const remainingAmountTooltip = `
      باقی مانده تسویه آنی امروز بانک، 
      ${this.remainedInLetter}
      ${this.currencyName}
       است`

      if (!this.isBankHealthy)
        return `${remainingAmountTooltip}  بانک موقتا اختلال دارد.`

      return remainingAmountTooltip
    },
    getRemainedAmount() {
      return this.bankLimits?.remained
    },
    remainedInLetter() {
      return this.priceInLetters(
        parseFloat(this.getRemainedAmount / 10).toString()
      )
    },
    isBankHealthy() {
      return this.bank.a2a?.is_healthy
    },
    calcRemainingPercentage() {
      if (this.bankLimits?.total === -1) return 100
      if (!this.bankLimits?.remained) return 0
      return (this.bankLimits?.remained * 100) / this.bankLimits?.total
    },
  },
}
</script>

//it should not be scoped
<style lang="scss">
.green3 {
  color: rgba(76, 159, 135, 0.3);
}
.red3 {
  color: rgba(212, 77, 66, 0.3);
}
.outerProgressCircle {
  width: 46px !important;
  height: 46px !important;

  .v-progress-circular__info {
    margin: 0 !important;
  }
  > .v-progress-circular__info {
    width: 100%;
    height: 100%;
  }
  > svg {
    > .v-progress-circular__underlay {
      stroke: $van_color08 !important;
    }
  }
}
.innerProgressCircle {
  width: 87% !important;
  height: 87% !important;
  > svg {
    top: 1px !important;
    > .v-progress-circular__underlay {
      stroke: $van_color06 !important;
    }
  }
}

.grayscale {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1);
}
</style>
