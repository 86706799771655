<template>
  <div class="navigation" style="flex: 1 1 auto">
    <div v-if="$store.state.isUser" class="top">
      <h2 v-if="user?.hasOwnProperty('name')">
        {{ user.name }}
      </h2>
      <span v-else class="default_gray dark" />
      <wallet center dark with-label />
      <v-avatar
        size="60"
        style="
          border: 2px solid #fff;
          border-radius: 50%;
          background-color: white;
          box-sizing: content-box;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
        "
      >
        <v-img
          v-if="user.avatar"
          :src="$store.state.storage_base_url + user.avatar"
          alt="avatar"
        />
        <v-img v-else :src="require('@/assets/img/default.png')" alt="avatar" />
      </v-avatar>
    </div>

    <div v-else class="top">
      <h2 v-if="business?.hasOwnProperty('business_name_fa')">
        {{ business.business_name_fa }}
      </h2>
      <span v-else class="default_gray dark" />
      <wallet :access="business.role !== 'developer'" center dark with-label />
      <v-avatar
        size="60"
        style="
          border: 2px solid #fff;
          border-radius: 50%;
          background-color: white;
          box-sizing: content-box;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
        "
      >
        <v-img
          v-if="business.avatar"
          :src="$store.state.storage_base_url + business.avatar"
          alt="avatar"
        />
        <v-img
          v-else
          :src="require('@/assets/img/businessDefault.png')"
          alt="avatar"
        />
      </v-avatar>
    </div>

    <v-divider />

    <div class="py-0 px-5" style="position: relative">
      <div class="floatingBtn">
        <businessList />
      </div>

      <skeleton
        v-if="
          !(
            $store.state.isUser ||
            (!$store.state.isUser &&
              $store.state.business?.hasOwnProperty('business_name') &&
              $store.state.business?.hasOwnProperty('tools'))
          )
        "
      />
      <v-list v-else class="menu_v_list bold_hover pt-11">
        <v-list-item
          v-if="
            $store.state.isUser ||
            (!$store.state.isUser && $store.state.business.role !== 'developer')
          "
          :to="$store.getters.urlParam"
          exact
        >
          <v-list-item-action>
            <svg
              class="svgs"
              height="14"
              viewBox="0 0 14 14"
              width="14"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs />
              <path
                class="a"
                d="M10.778,3V7.667H17V3M10.778,17H17V9.222H10.778M3,17H9.222V12.333H3m0-1.556H9.222V3H3Z"
                transform="translate(-3 -3)"
              />
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ dashboard.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="
            $store.state.isUser ||
            (!$store.state.isUser && $store.state.business.role !== 'developer')
          "
          :to="$store.getters.urlParam + '/report'"
          exact
        >
          <v-list-item-action>
            <svg
              class="svgs"
              height="16"
              viewBox="0 0 14.4 16"
              width="14.4"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs />
              <path
                class="a"
                d="M3,18V6.8H6.2V18H3m5.6,0V2h3.2V18H8.6m5.6,0V11.6h3.2V18Z"
                transform="translate(-3.001 -2)"
              />
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ report.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="
            !$store.state.isUser &&
            $store.state.business?.hasOwnProperty('tools') &&
            $store.state.business.tools.accountant &&
            $store.state.business.tools.accountant?.status === 'ACTIVE'
          "
          :to="$store.getters.urlParam + '/accountant'"
          exact
        >
          <v-list-item-action>
            <accountantIcon :navigation="true" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>گزارش‌های مالی</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="
            !$store.state.isUser &&
            !['accountant', 'observer', 'operator'].includes(
              $store.state.business.role
            ) &&
            $store.state.business?.hasOwnProperty('tools') &&
            $store.state.business.tools.log &&
            $store.state.business.tools.log?.status === 'ACTIVE'
          "
          :to="$store.getters.urlParam + '/log'"
          exact
        >
          <v-list-item-action>
            <svg
              class="svgs"
              height="15.429"
              viewBox="0 0 18 15.429"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs />
              <path
                class="a"
                d="M11.714,7.286H10.429v4.286L14.1,13.749l.617-1.037-3-1.783V7.286M11.286,3a7.714,7.714,0,0,0-7.714,7.714H1l3.394,3.454,3.463-3.454H5.286a6.029,6.029,0,1,1,1.766,4.234L5.834,16.166a7.625,7.625,0,0,0,5.451,2.263A7.714,7.714,0,0,0,11.286,3"
                transform="translate(-1 -3)"
              />
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ log.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="
            !$store.state.isUser &&
            $store.state.business.role !== 'developer' &&
            $store.state.business?.hasOwnProperty('tools') &&
            $store.state.business.tools.settlement_batch?.hasOwnProperty(
              'status'
            ) &&
            $store.state.business.tools.settlement_batch?.status === 'ACTIVE'
          "
          :to="$store.getters.urlParam + '/batchesSettlement'"
          exact
        >
          <v-list-item-action>
            <svg
              class="svgs"
              height="13"
              viewBox="0 0 17.5 13"
              width="17.5"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <!-- <style>.a{fill:#fff;}</style> -->
              </defs>
              <path
                class="a"
                d="M18.5,9.5A1.5,1.5,0,1,1,17,11,1.5,1.5,0,0,1,18.5,9.5Zm0-5A1.5,1.5,0,1,1,17,6,1.5,1.5,0,0,1,18.5,4.5Zm0,10A1.5,1.5,0,1,1,17,16,1.5,1.5,0,0,1,18.5,14.5Zm-4,2.5H3.5a1,1,0,0,1,0-2h11a1,1,0,0,1,0,2Zm0-5H3.5a1,1,0,0,1,0-2h11a1,1,0,0,1,0,2Zm1-6a1,1,0,0,1-1,1H3.5a1,1,0,0,1,0-2h11A1,1,0,0,1,15.5,6Z"
                transform="translate(-2.5 -4.5)"
              />
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ batchesSettlement.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="$store?.state?.business?.tools?.MPG?.status === 'ACTIVE'"
          :to="$store.getters.urlParam + '/mpg/'"
          exact
        >
          <v-list-item-action>
            <svg
              class="svgs"
              width="18"
              height="15"
              viewBox="0 0 12 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="a"
                d="M1.1724 10H10.5674C11.2301 10 11.7674 9.47796 11.7674 8.83398V7.97351C11.488 8.14686 11.1561 8.24732 10.8 8.24732C10.7793 8.24732 10.7586 8.24698 10.738 8.2463V8.82752H1.1724V1.16894H10.5879V1.84623C10.6575 1.8383 10.7283 1.83422 10.8 1.83422C11.1561 1.83422 11.488 1.93468 11.7674 2.10802V1.17108C11.7674 0.860829 11.644 0.563172 11.4226 0.343656C11.2012 0.12414 10.9012 0.000409737 10.5879 2.36153e-05H1.1724C1.01734 -0.00096219 0.863188 0.0289274 0.719863 0.0875325C0.576538 0.146138 0.446922 0.232484 0.337725 0.341522C0.228529 0.450559 0.142885 0.57972 0.084882 0.72215C0.0268787 0.864579 -0.00213262 1.01751 0.00012205 1.17108V8.82966C-0.000555461 8.9828 0.0291912 9.13472 0.0877552 9.27645C0.146319 9.41818 0.231716 9.54693 0.340598 9.65566C0.449481 9.76438 0.580125 9.85049 0.722737 9.90965C0.865348 9.9688 1.01778 9.99943 1.1724 10Z"
              />
              <path
                class="a"
                d="M12 3.58324C12 4.22722 11.4627 4.74926 10.8 4.74926C10.1373 4.74926 9.6 4.22722 9.6 3.58324C9.6 2.93927 10.1373 2.41723 10.8 2.41723C11.4627 2.41723 12 2.93927 12 3.58324Z"
              />
              <path
                class="a"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 6.49829C12 7.14227 11.4627 7.66431 10.8 7.66431C10.1373 7.66431 9.6 7.14227 9.6 6.49829C9.6 5.85432 10.1373 5.33227 10.8 5.33227C11.4627 5.33227 12 5.85432 12 6.49829ZM11.4 6.49829C11.4 6.82028 11.1314 7.0813 10.8 7.0813C10.4686 7.0813 10.2 6.82028 10.2 6.49829C10.2 6.1763 10.4686 5.91528 10.8 5.91528C11.1314 5.91528 11.4 6.1763 11.4 6.49829Z"
              />
              <path
                class="a"
                d="M3.78845 3.614C3.78845 3.27503 4.07125 3.00024 4.4201 3.00024H8.20998C8.55883 3.00024 8.84163 3.27503 8.84163 3.614C8.84163 3.95297 8.55883 4.22776 8.20998 4.22776H4.4201C4.07125 4.22776 3.78845 3.95297 3.78845 3.614Z"
              />
              <path
                class="a"
                d="M3.15806 5.91528C2.80921 5.91528 2.52642 6.19007 2.52642 6.52904C2.52642 6.86801 2.80921 7.1428 3.15806 7.1428H8.21124C8.56009 7.1428 8.84289 6.86801 8.84289 6.52904C8.84289 6.19007 8.56009 5.91528 8.21124 5.91528H3.15806Z"
              />
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ mpg.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="
            !$store.state.isUser &&
            !['accountant', 'observer', 'operator'].includes(
              $store.state.business.role
            ) &&
            $store.state.business?.hasOwnProperty('tools') &&
            !['INACTIVE', 'HARD_REJECT'].includes(
              $store?.state?.business?.tools?.ipg?.status
            )
          "
          :to="$store.getters.urlParam + '/ipg'"
          exact
        >
          <v-list-item-action>
            <svg
              class="svgs"
              height="16"
              viewBox="0 0 18 16"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="a"
                d="M18604-5862a2,2,0,0,1-2-2v-12a2,2,0,0,1,2-2h14a2,2,0,0,1,2,2v12a2,2,0,0,1-2,2Zm-1-3a2,2,0,0,0,2,2h12a2,2,0,0,0,2-2v-7.5h-16Zm7-10a1,1,0,0,0,1,1,1,1,0,0,0,1-1,1,1,0,0,0-1-1A1,1,0,0,0,18610-5875Zm-3,0a1,1,0,0,0,1,1,1,1,0,0,0,1-1,1,1,0,0,0-1-1A1,1,0,0,0,18607-5875Zm-3,0a1,1,0,0,0,1,1,1,1,0,0,0,1-1,1,1,0,0,0-1-1A1,1,0,0,0,18604-5875Z"
                transform="translate(-18602 5878)"
              />
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ ipg.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="
            !$store.state.isUser &&
            $store.state.business.role !== 'developer' &&
            $store?.state?.business?.hasOwnProperty('tools') &&
            $store?.state?.business?.tools?.requestMoney?.status === 'ACTIVE'
          "
          :to="$store.getters.urlParam + '/request_money'"
          exact
        >
          <v-list-item-action>
            <svg
              class="svgs"
              height="14"
              viewBox="0 0 18 14"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs />
              <g transform="translate(0 -21)">
                <path
                  class="a"
                  d="M18,22.005A.944.944,0,0,0,17.1,21H.9A.934.934,0,0,0,0,22.005V23.9H18Z"
                  transform="translate(0)"
                />
                <path
                  class="a"
                  d="M.9,60.688H17.1a.934.934,0,0,0,.9-1.005V52H0v7.683A.934.934,0,0,0,.9,60.688Zm.552-3.118a.9.9,0,0,1,.842-.971H6.416a.9.9,0,0,1,.842.971v.341a1.011,1.011,0,0,1-.842,1.073H2.294a1,1,0,0,1-.842-1.073Z"
                  transform="translate(0 -25.688)"
                />
              </g>
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ request_money.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!--direct-debit-->
        <v-list-item
          v-if="
            !$store.state.isUser &&
            !['accountant', 'observer', 'operator'].includes(
              $store.state.business.role
            ) &&
            $store?.state?.business?.hasOwnProperty('tools') &&
            $store?.state?.business?.tools?.subscription?.status === 'ACTIVE'
          "
          :to="$store.getters.urlParam + '/subscription'"
          exact
        >
          <v-list-item-action>
            <svg
              class="svgs"
              height="16.004"
              viewBox="0 0 19.998 16.004"
              width="19.998"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(-1541.006 -619.004)">
                <path
                  class="a"
                  d="M20534.441,13899.889h-8.9a1.6,1.6,0,0,1-1.594-1.607v-9.67a1.584,1.584,0,0,1,1.594-1.607h12.8a1.607,1.607,0,0,1,1.605,1.607V13891l-1.605.182v-2.572h-12.8v9.67h8.9v1.607Z"
                  transform="translate(-18982.943 -13268)"
                />
                <g class="b" transform="translate(1545.001 623)">
                  <rect class="c" height="1.6" rx="0.5" width="8" />
                  <rect class="d" height="0.6" width="7" x="0.5" y="0.5" />
                </g>
                <g class="b" transform="translate(1545.001 626)">
                  <rect class="c" height="1.6" rx="0.5" width="5" />
                  <rect class="d" height="0.6" width="4" x="0.5" y="0.5" />
                </g>
                <g transform="translate(1552.999 625)">
                  <path
                    class="a"
                    d="M135.958,4.71A.5.5,0,0,1,135.5,5h-2v4.5a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1-.5-.5V5h-2a.5.5,0,0,1-.376-.83l3.5-4a.5.5,0,0,1,.752,0l3.5,4A.5.5,0,0,1,135.958,4.71Z"
                    transform="translate(-127.998)"
                  />
                </g>
              </g>
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ subscription.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!--pbv-->
        <v-list-item
          v-if="
            !$store.state.isUser &&
            !['accountant', 'observer', 'operator'].includes(
              $store.state.business.role
            ) &&
            $store.state.business.status &&
            $store.state.business.hasOwnProperty('tools') &&
            $store.state.business.tools.PBV &&
            $store.state.business.tools.PBV.status === 'ACTIVE'
          "
          :to="$store.getters.urlParam + '/pbv'"
          exact
        >
          <v-list-item-action>
            <svg
              class="svgs"
              fill="none"
              height="20"
              viewBox="0 0 24 20"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="a"
                d="M11.3623 18.72C7.64115 18.5444 4.68064 15.4771 4.68064 11.7073C4.68064 7.8322 7.82838 4.68293 11.7016 4.68293C15.4695 4.68293 18.5353 7.64488 18.7109 11.3678L16.2535 10.6302C15.7738 8.55805 13.9132 7.02439 11.7016 7.02439C9.11555 7.02439 7.02097 9.12 7.02097 11.7073C7.02097 13.92 8.55388 15.7815 10.6251 16.2615L11.3623 18.72ZM23.4032 11.7073C23.4032 12.0585 23.3915 12.4098 23.3564 12.761L21.0512 12.0702C21.0629 11.9532 21.0629 11.8244 21.0629 11.7073C21.0629 6.53268 16.8737 2.34146 11.7016 2.34146C6.5295 2.34146 2.34032 6.53268 2.34032 11.7073C2.34032 16.882 6.5295 21.0732 11.7016 21.0732C11.8186 21.0732 11.9473 21.0732 12.0644 21.0615L12.7548 23.3678C12.4037 23.4029 12.0527 23.4146 11.7016 23.4146C5.24232 23.4146 0 18.1698 0 11.7073C0 5.24488 5.24232 0 11.7016 0C18.1609 0 23.4032 5.24488 23.4032 11.7073ZM18.9917 16.6946L23.4032 15.2195L11.7016 11.7073L15.2121 23.4146L16.6865 19.001L21.6831 24L24 21.682L18.9917 16.6946Z"
              />
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ pbv.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- check this validations -->
        <v-list-item
          v-if="
            !$store.state.isUser &&
            !['developer', 'observer', 'operator'].includes(
              $store.state.business.role
            ) &&
            $store.state.business?.hasOwnProperty('tools') &&
            ($store.state.business.tools.subscription?.status === 'ACTIVE' ||
              $store.state.business.tools.customer?.status === 'ACTIVE')
          "
          :to="$store.getters.urlParam + '/customer'"
          exact
        >
          <v-list-item-action>
            <svg
              class="svgs"
              height="18"
              viewBox="0 0 14.727 18"
              width="14.727"
              xmlns="http://www.w3.org/2000/svg"
            >
              >
              <path
                class="a"
                d="M10.364,1,3,4.273V9.182A10.182,10.182,0,0,0,10.364,19a10.182,10.182,0,0,0,7.364-9.818V4.273L10.364,1m0,3.273A2.455,2.455,0,1,1,7.909,6.727a2.455,2.455,0,0,1,2.455-2.455m4.2,9.818a7.928,7.928,0,0,1-4.2,3.207,7.928,7.928,0,0,1-4.2-3.207,8.317,8.317,0,0,1-.712-1.252c0-1.35,2.217-2.455,4.909-2.455s4.909,1.08,4.909,2.455A8.317,8.317,0,0,1,14.561,14.091Z"
                transform="translate(-3 -1)"
              />
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ customer.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- check this validations -->
        <v-list-item
          v-if="
            !$store.state.isUser &&
            $store.state.business.role !== 'developer' &&
            $store.state.business.role !== 'observer' &&
            $store.state.business?.hasOwnProperty('tools') &&
            $store.state.business.tools.subscription?.status === 'ACTIVE'
          "
          :to="$store.getters.urlParam + '/invoice'"
          exact
        >
          <v-list-item-action>
            <svg
              class="svgs"
              height="18.003"
              viewBox="0 0 16.004 18.003"
              width="16.004"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(0 -3.07)">
                <path
                  class="a"
                  d="M15.908,3.07H.923a.51.51,0,0,0-.509.51V20.56a.514.514,0,0,0,.3.47.509.509,0,0,0,.55-.09L3.041,19.3a.507.507,0,0,1,.685,0l1.162,1.03a1.527,1.527,0,0,0,2.042,0L8.074,19.3a.508.508,0,0,1,.683,0L9.9,20.33a1.526,1.526,0,0,0,2.041,0l1.162-1.03a.507.507,0,0,1,.685,0l1.772,1.64a.509.509,0,0,0,.55.09.514.514,0,0,0,.305-.47V3.58a.51.51,0,0,0-.509-.51ZM14.794,18.2l-.313-.247a1.523,1.523,0,0,0-2.054-.017l-1.162,1.037a.509.509,0,0,1-.681,0L9.44,17.939a1.525,1.525,0,0,0-2.048,0L6.247,18.972a.509.509,0,0,1-.68,0L4.4,17.937a1.523,1.523,0,0,0-2.054.017l-.337.247V4.67h12.78V18.2Z"
                  transform="translate(-0.414)"
                />
                <g class="b" transform="translate(3 8.07)">
                  <rect class="c" height="1.7" rx="0.85" width="9" />
                  <rect
                    class="d"
                    height="0.7"
                    rx="0.35"
                    width="8"
                    x="0.5"
                    y="0.5"
                  />
                </g>
                <g class="b" transform="translate(3 11.74)">
                  <rect class="c" height="1.7" rx="0.85" width="6" />
                  <rect
                    class="d"
                    height="0.7"
                    rx="0.35"
                    width="5"
                    x="0.5"
                    y="0.5"
                  />
                </g>
              </g>
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ invoice.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="p2pStatus"
          :to="$store.getters.urlParam + '/p2p'"
          exact
        >
          <v-list-item-action>
            <svg
              class="svgs"
              height="15.449"
              viewBox="0 0 17.003 15.449"
              width="17.003"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(-194.234 -462.004)">
                <path
                  class="a"
                  d="M225.544,21.469l-1.618,1.616a1.145,1.145,0,0,1-.807.334H219.85a.341.341,0,0,1-.242-.584l2.258-2.251a.341.341,0,0,0-.242-.584h-3.172a.572.572,0,0,1-.572-.572V12.572a.572.572,0,0,1,.572-.572h6.855a.572.572,0,0,1,.572.572v8.09A1.144,1.144,0,0,1,225.544,21.469Z"
                  transform="translate(-23.646 450.004)"
                />
                <g transform="translate(189 458.809)">
                  <path
                    class="b"
                    d="M16.75,9.146,14.444,6.834a.413.413,0,0,0-.705.29V8.608H10.709a.829.829,0,1,0,0,1.657H13.73v1.483a.411.411,0,0,0,.705.29l2.307-2.312a.407.407,0,0,0,.008-.58Z"
                    transform="translate(5.369 1.483)"
                  />
                  <path
                    class="b"
                    d="M8.642,10.824,6.336,13.136a.425.425,0,0,0,0,.588l2.307,2.312a.413.413,0,0,0,.705-.29V14.255h3.021a.829.829,0,1,0,0-1.657H9.348V11.114A.411.411,0,0,0,8.642,10.824Z"
                    transform="translate(6.369 2.483)"
                  />
                </g>
              </g>
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ p2p.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="
            !$store.state.isUser &&
            $store.state.business.role !== 'developer' &&
            $store.state.business?.hasOwnProperty('tools') &&
            $store.state.business.tools.kerdar?.status === 'ACTIVE'
          "
          :to="$store.getters.urlParam + '/kerdar'"
          exact
        >
          <v-list-item-action>
            <svg
              class="svgs"
              height="20"
              viewBox="0 0 20 20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="auto_graph_black_24dp" transform="translate(-1)">
                <rect
                  id="Rectangle_2046"
                  data-name="Rectangle 2046"
                  fill="none"
                  height="20"
                  transform="translate(1)"
                  width="20"
                />
                <path
                  id="Path_6327"
                  d="M12.873,9.218,11,8.364l1.873-.855.855-1.873.855,1.873,1.873.855-1.873.855-.855,1.873ZM3.727,12.909l.855-1.873,1.873-.855L4.582,9.327,3.727,7.455,2.873,9.327,1,10.182l1.873.855ZM7.818,8.364l.991-2.191L11,5.182,8.809,4.191,7.818,2,6.827,4.191l-2.191.991,2.191.991ZM4.182,18.818l5.455-5.464,3.636,3.636L21,8.3,19.718,7.018l-6.445,7.245L9.636,10.627,2.818,17.455Z"
                  data-name="Path 6327"
                />
              </g>
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ kerdar.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="
            !$store.state.isUser &&
            $store.state.business?.hasOwnProperty('tools') &&
            $store?.state?.business?.tools?.miando?.viewable
          "
          :to="$store.getters.urlParam + '/miando'"
          exact
        >
          <v-list-item-action>
            <svg
              class="svgs"
              width="17"
              height="17"
              viewBox="0 0 28 28"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.4 16.333H5.6C4.4402 16.333 3.5 17.3777 3.5 18.6663C3.5 19.955 4.4402 20.9997 5.6 20.9997H22.4C23.5598 20.9997 24.5 19.955 24.5 18.6663C24.5 17.3777 23.5598 16.333 22.4 16.333Z"
              />
              <path
                d="M22.4 16.333H5.6C4.4402 16.333 3.5 17.3777 3.5 18.6663C3.5 19.955 4.4402 20.9997 5.6 20.9997H22.4C23.5598 20.9997 24.5 19.955 24.5 18.6663C24.5 17.3777 23.5598 16.333 22.4 16.333Z"
              />
              <path
                d="M22.4 8H5.6C4.4402 8 3.5 9.04467 3.5 10.3333C3.5 11.622 4.4402 12.6667 5.6 12.6667H22.4C23.5598 12.6667 24.5 11.622 24.5 10.3333C24.5 9.04467 23.5598 8 22.4 8Z"
              />
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ miando.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="$store.state.accountsList?.length > 0"
          :to="$store.getters.urlParam + '/bank-invoice'"
          exact
        >
          <v-list-item-action>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.6663 14.0033H2.33301C1.80257 14.0033 1.29387 13.7925 0.918794 13.4175C0.543722 13.0424 0.333008 12.5337 0.333008 12.0033V1.33659C0.333008 1.15978 0.403246 0.990208 0.52827 0.865184C0.653294 0.74016 0.822863 0.669922 0.999674 0.669922H10.333C10.5098 0.669922 10.6794 0.74016 10.8044 0.865184C10.9294 0.990208 10.9997 1.15978 10.9997 1.33659V9.33659H13.6663V12.0033C13.6663 12.5337 13.4556 13.0424 13.0806 13.4175C12.7055 13.7925 12.1968 14.0033 11.6663 14.0033ZM10.9997 10.6699V12.0033C10.9997 12.1801 11.0699 12.3496 11.1949 12.4747C11.32 12.5997 11.4895 12.6699 11.6663 12.6699C11.8432 12.6699 12.0127 12.5997 12.1377 12.4747C12.2628 12.3496 12.333 12.1801 12.333 12.0033V10.6699H10.9997ZM9.66634 12.6699V2.00326H1.66634V12.0033C1.66634 12.1801 1.73658 12.3496 1.8616 12.4747C1.98663 12.5997 2.1562 12.6699 2.33301 12.6699H9.66634ZM2.99967 4.00326H8.33301V5.33659H2.99967V4.00326ZM2.99967 6.66992H8.33301V8.00326H2.99967V6.66992ZM2.99967 9.33659H6.33301V10.6699H2.99967V9.33659Z"
                fill="#717171"
              />
            </svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ bankInvoice.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import businessList from "./businessList"
import skeleton from "./navigationDrawerSkeleton"
import Wallet from "./walletForUserOrBusiness.vue"
import accountantIcon from "../../assets/img/newIcon/accountantIcon.vue"
import servicesRepository from "@/services/servicesRepository"

export default {
  name: "NavigationDrawer",
  components: {
    businessList,
    skeleton,
    Wallet,
    accountantIcon,
  },
  data() {
    return {
      changePicture: { title: "تغییر تصویر پروفایل" },
      home: { title: "همه کسب‌و‌کارها", icon: "home", to: "/" },
      changePassword: { title: "تغییر رمز عبور", icon: "lock", to: "/setting" },
      dashboard: {
        title: "میز کار",
        icon: "dashboard",
        to: this.$store.getters.urlParam,
      },
      request_money: {
        title: "فرم پرداخت",
        image: "MoneyRequest.svg",
        to: this.$store.getters.urlParam + "/request_money",
      },
      mpg: {
        title: "پرداخت یکپارچه",
        image: "MoneyRequest.svg",
        to: this.$store.getters.urlParam + "/mpg/",
      },
      customer: {
        title: "مشتریان",
        image: "PaymentGateway.svg",
        to: this.$store.getters.urlParam + "/customer",
      },
      ipg: {
        title: "درگاه پرداخت",
        image: "PaymentGateway.svg",
        to: this.$store.getters.urlParam + "/ipg",
      },
      subscription: {
        title: "پرداخت خودکار",
        image: "PaymentGateway.svg",
        to: this.$store.getters.urlParam + "/subscription",
      },
      pbv: {
        title: "پرداخت با وندار",
        icon: "bar_chart",
        to: this.$store.getters.urlParam + "/pbv",
      },
      report: {
        title: "واریز و برداشت",
        icon: "bar_chart",
        to: this.$store.getters.urlParam + "/report",
      },
      log: {
        title: "گزارش فراخوانی‌",
        icon: "history",
        to: this.$store.getters.urlParam + "/log",
      },
      invoice: {
        title: "فاکتورها",
        image: "invoiceIcon.svg",
        to: this.$store.getters.urlParam + "/invoice",
      },
      p2p: {
        title: "انتقال وجه",
        to: this.$store.getters.urlParam + "/p2p",
      },
      kerdar: {
        title: "کردار",
        to: this.$store.getters.urlParam + "/kerdar",
      },
      batchesSettlement: {
        title: "برداشت گروهی",
        to: this.$store.getters.urlParam + "/batchesSettlement",
      },
      setting: {
        title: "تنظیمات",
        icon: "settings",
        to: this.$store.getters.urlParam + "/setting",
      },
      checkouts: {
        title: "صورتحساب‌ها",
        to: this.$store.getters.urlParam + "/checkouts",
      },
      miando: {
        title: "معامله امن",
        image: "PaymentGateway.svg",
        to: this.$store.getters.urlParam + "/miando",
      },
      bankInvoice: {
        title: "صورتحساب‌ها",
      },
      listOfBusiness: [],
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    business() {
      if (!this.$store.state.isUser) return this.$store.state.business
      else return {}
    },
    p2pStatus() {
      return (
        (this.$store.state.isUser &&
          this.$store.state.user?.hasOwnProperty("tools") &&
          this.$store.state.user.tools.p2p?.status === "ACTIVE") ||
        (!this.$store.state.isUser &&
          this.$store.state.business?.hasOwnProperty("tools") &&
          this.$store.state.business.tools.p2p?.status === "ACTIVE" &&
          this.$store.state.business.role !== "developer")
      )
    },
  },
  mounted() {
    if (this.$store.state.accountsList?.length === 0) {
      this.getAccountsList()
    }
  },
  methods: {
    async getAccountsList() {
      if (this.$store.state.isUser) return
      const response = await servicesRepository(
        this
      ).settlementServices.httpGetAccountsList()
      this.$store.commit("setAccountsList", response.data.data)
    },
  },
}
</script>
<style lang="scss">
.v-list-item--active {
  svg {
    path {
      fill: white;
    }
  }
}
</style>
